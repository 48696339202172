import { Measure } from '~/shared/models/report/measure';
import { SortOrder } from '~/shared/models/sorting/sort-order';
import { Score } from '~/shared/models/report/score';

/**
 * A type definition fields that measures can be sorted by
 *
 * - 'sortOrder' is used to sort by an explicit sort order.
 * - 'name' is used to sort alphabetically by name.
 */
export type MeasureSort = 'sortOrder' | 'name' | 'score';

/**
 * Sorts the given array of measures based on a specified field and order.
 *
 * @param {Measure[]} measures - The array of measures to be sorted.
 * @param {MeasureSort} [sortField='sortOrder'] - The field to sort the measures by. Defaults to 'sortOrder'.
 * @param {SortOrder} [sortOrder='asc'] - The sort order, either 'asc' for ascending or 'desc' for descending. Defaults to 'asc'.
 * @param {Score[]} scores - Array of scores for the measures. This is required when sorting by score
 * @return {Measure[]} A new array of measures sorted based on the specified criteria.
 */
export function sortMeasures(
  measures: Measure[],
  sortField: MeasureSort = 'sortOrder',
  sortOrder: SortOrder = 'asc',
  scores: Score[] = [],
): Measure[] {
  // Create a copy first to not mutate the original array
  const sorted = measures.slice();

  // Now sort in-place
  if (sortField === 'name') {
    sorted.sort(compareMeasuresByLabel);
  } else if (sortField === 'score') {
    sorted.sort((left, right) => compareByScore(left, right, scores));
  } else {
    sorted.sort(compareMeasuresByOrder);
  }

  // If needed, reverse the order
  if (sortOrder === 'desc') {
    sorted.reverse();
  }

  return sorted;
}

function compareByScore(
  left: Measure,
  right: Measure,
  scores: Score[],
): number {
  const leftScore = scores.find(score => score.measureId === left.measureId);
  const rightScore = scores.find(score => score.measureId === right.measureId);

  // If they are both undefined then they are equal
  if (!leftScore && !rightScore) {
    return 0;
  }

  // If left is undefined then right is more
  if (!leftScore && rightScore) {
    return -1;
  }

  // If right is undefined then left is more
  if (leftScore && !rightScore) {
    return 1;
  }

  // Everything is defined - compare the actual scores
  return (
    leftScore!.formattedNumericScore - rightScore!.formattedNumericScore ||
    compareMeasuresByLabel(left, right)
  );
}

function compareMeasuresByOrder(left: Measure, right: Measure): number {
  return (
    left.sortOrder - right.sortOrder || compareMeasuresByLabel(left, right)
  );
}

function compareMeasuresByLabel(left: Measure, right: Measure): number {
  return (
    left.label.localeCompare(right.label) ||
    left.measureId.localeCompare(right.measureId)
  );
}
